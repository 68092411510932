<template>
  <div>
    <Modal
      v-model="showModalCreate"
      title="Criar novo Ano"
      :enableClose="false"
    >
      <div class="form-group">
        <label for="titulo">Título</label>
        <input
          id="titulo"
          v-model="item.titulo"
          type="text"
          class="form-control"
        />
      </div>
      <hr class="full-hr" />
      <div class="row">
        <div class="col-sm-12">
          <div class="float-right">
            <button
              type="button"
              class="btn btn-classic"
              :disabled="!formIsValid"
              @click.prevent="salvar"
            >
              Salvar
            </button>
            <button
              type="button"
              class="btn btn-secondary m-2"
              @click="showModalCreate = false"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Modal>

    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />
      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="
              page-header
              d-flex
              justify-content-between
              align-items-center
              mb-3
              pt-4
            "
          >
            <h1 class="color-sec">Anos</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Anos</li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          v-model="filter"
                          type="search"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-md-3 d-flex justify-content-md-end">
                  <!-- <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button
                        class="btn btn-classic"
                        @click.prevent="openModalCreate"
                      >
                        <i class="fa fa-plus" /> NOVO
                      </button>
                    </li>
                  </ul> -->
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="
                  block__classic
                  table
                  table__classic
                  table-striped
                  table-borderless
                  table-hover
                  text-center
                  mb-4
                "
              >
                <thead>
                  <tr>
                    <th scope="col">Título</th>
                    <th scope="col" class="coluna-lixeira-restaurar" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in filteredRows" :key="item.id">
                    <td
                      class="text-left pl-4"
                      v-html="highlightMatches(item.titulo)"
                    />
                    <td>
                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="restore(item.id)" v-if="podeEditar(item)"
                      >
                        Restaurar
                      </button>
                      <!-- <button
                        class="btn btn-sm btn-danger m-2"
                        @click.prevent="deletar(item.id)"
                      >
                        <i class="far fa-trash-alt" />
                      </button> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import Swal from "sweetalert2";
import { checkUserHaveRole } from "@/tools";
import { constantes } from "@/constants";
import store from "@/store";
// import Navigator from "../../../components/anos_turmas/AnosTurmasNavigator.vue";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    Modal: VueModal,
    // Navigator,
  },
  data() {
    return {
      showLeftMenu: true,
      showModalCreate: false,
      showInfoInstituicao: true,
      id: null,
      index: -1,
      item: {
        id: null,
        titulo: "",
        instituicao: null,
        instituicaoSelecionada: null,
      },
      items: [],
      filter: "",
      instituicoes: [],
      idInstituicao: localStorage.getItem(constantes.STORAGE_UNIAVAN_ID_INSTITUICAO)
    };
  },
  computed: {
    filteredRows() {
      return this.items.filter((row) => {
        const searchTerm = this.filter.toLowerCase();
        const text = row.titulo.toLowerCase();
        return text.includes(searchTerm);
      });
    },
    formIsValid() {
      if (!this.item.titulo || this.item.instituicaoSelecionada == "") {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadData();
    if (checkUserHaveRole([constantes.ROLE_SUPER_ADMIN])) {
      this.loadInstituicoes();
    } else {
      this.showInfoInstituicao = false;
    }
  },
  methods: {
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    openModalCreate() {
      this.showModalCreate = true;
      this.item = {};
      this.index = -1;
      this.item.instituicao = null;
    },
    salvar() {
      this.saveServer();
    },
    restore(id) {
      // /api/professors/{id}/recovery
      this.$api
        .put("ano_ensinos/" + id + "/recovery", {})
        .then((d) => {
          console.log(d);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Ano restaurado com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          });
          // this.items.splice(index, 1);
          setTimeout(function () {
            location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          this.$root.$emit("Spinner::hide");
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocorreu um erro.",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    deletar(index) {
      console.log(index);
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção desse ano?",
        text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      })
        .then((res) => {
          if (res.isConfirmed) {
            this.$root.$emit("Spinner::show");
            this.$api.delete("ano_ensinos/" + index).then(() => {
              this.$root.$emit("Spinner::hide");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Ano removido com sucesso!",
                showConfirmButton: false,
                timer: 1500,
              });
              // this.items.splice(index, 1);
              // this.loadData();
              setTimeout(function () {
                location.reload();
              }, 1500);
            });
          }
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocorreu um erro no processo!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      // this.items.splice(index, 1);
    },
    editar(index) {
      this.index = index;
      this.item = this.items[index];
      this.item.instituicaoSelecionada = this.item.instituicao.id;
      this.showModalCreate = true;
    },
    podeEditar(item){
      return checkUserHaveRole([constantes.ROLE_SUPER_ADMIN]) || (item.instituicao && item.instituicao.id == this.idInstituicao)
    },
    loadInstituicoes() {
      this.$root.$emit("Spinner::show");

      this.$api
        .get("instituicao_ensinos")
        .then((response) => {
          this.instituicoes = response.data;
          this.$root.$emit("Spinner::hide");
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Você não tem acesso à esta ação neste módulo",
            showConfirmButton: false,
            timer: 1500,
          });

          this.$root.$emit("Spinner::hide");
        });
    },
    loadData() {
      this.$root.$emit("Spinner::show");
      this.$api.get("ano_ensinos").then((response) => {
        console.log(response.data);

        if (this.idInstituicao == 0) {
          this.items = response.data.filter(res => res.trashAt != null);
        } else {
          this.items = response.data.filter(res => res.trashAt != null && (!res.instituicao || res.instituicao.id == this.idInstituicao)).sort();
        }
        this.$root.$emit("Spinner::hide");
      });
    },
    saveServer() {
      this.$root.$emit("Spinner::show");
      this.item.instituicao = this.item.instituicaoSelecionada;
      this.showModalCreate = false;

      if (this.index >= 0) {
        this.$api
          .put("ano_ensinos/" + this.item.id, this.item)
          .then(() => {
            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Ano alterado com sucesso!",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              // this.loadData();
              setTimeout(function () {
                location.reload();
              }, 1500);
            });
          })
          .catch((error) => {
            this.$root.$emit("Spinner::hide");
            if (error.response.status == 400) {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.detail +
                  "</span>",
                showConfirmButton: false,
                timer: 3500,
              });
            } else if (error.response.status == 401) {
              store.dispatch("auth/ActionSignOut");
              window._Vue.$router.push({ name: "login" });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.violations[0].message +
                  "</span>",
                showConfirmButton: false,
                timer: 2500,
              });
            }
          });
      } else {
        this.$api
          .post("ano_ensinos", this.item)
          .then(() => {
            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Ano cadastrado com sucesso!",
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              // this.loadData();

              this.showModalCreate = false;
            });
            setTimeout(function () {
              location.reload();
            }, 1500);
          })
          .catch((error) => {
            this.$root.$emit("Spinner::hide");
            if (error.response.status == 400) {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.detail +
                  "</span>",
                showConfirmButton: false,
                timer: 3500,
              });
            } else if (error.response.status == 401) {
              store.dispatch("auth/ActionSignOut");
              window._Vue.$router.push({ name: "login" });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.violations[0].message +
                  "</span>",
                showConfirmButton: false,
                timer: 2500,
              });
            }
          });
      }
    },
  },
};
</script>

<style></style>
